.mainpreviewEmail {
    display: flex;
    min-height: 550px;
    margin-top: 6%;
 
}

.left-column-preview {    
    margin-left: 5%;
    width: 15%;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: auto;

}

.email-list {
    display: flex;
    flex-direction: column;
 
}

.email-item {
    width: 300px;
    height: 40px;
    overflow: hidden;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.email-item:hover {
   background-color: #1E90FF; /* New background color on hover */
   color: #fff; /* New text color on hover */
 }

/* .even {
   background-color: lightblue;
 } */

.odd {
    background-color: #f2f2f2;
}

.scrollable-list-preview {
    max-height: 500px;
    font-size: small;
}

.header-with-underline {
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.active{
   background-color: #1E90FF; 
}

.preview-email{
    width: 73%;
    margin-left: 2%;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: auto;
    max-height: 500px;
    position: relative;
    z-index: 1;
    background-color: white;  
    padding: 16px;
}

.button-preview{
    margin-top: -2%;
    margin-right: 5%;
}

.loading-overlay-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);  
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }


  /* For screens smaller than 1365px wide and 931px tall */
@media (min-width: 1365px) and (min-height: 931px) {
    /* Your responsive styles go here */
    .mainpreviewEmail {
        display: flex;
        min-height: 827px;
        margin-top: 6%;
     
    }

    .preview-email{
        width: 73%;
        margin-left: 2%;
        border: 1px solid #ccc;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow: auto;
        max-height: 778px;
        position: relative;
        z-index: 1;
        background-color: white;  
        padding: 16px;
    }
  .button-preview  .button-container {
     
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

  }
  