.emailcustomize {
    display: flex;
    width: 100%;
    height: 100vh;
    margin-top: 6%;
}

.one {
    width: 34%;
    height: 100%;
    margin-left: 5%;

}

.maindiv {
    width: 54%;
    height: 100%;
    margin-left: 2%;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(194, 188, 188);
}

.two {
    height: 29%;
    /* background-color: aqua; */
    margin-bottom: 2%;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.three {
    height: 49%;
    /* background-color: aquamarine; */
}

.button-container {
    margin-top: 2%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    height: 270px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 270px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.email-customize-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.component-content {
    position: relative;
    z-index: 1;
    background-color: white;
    /* Adjust the background color as needed */
    padding: 16px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* Adjust the overlay color and opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}


/* For screens smaller than 1365px wide and 931px tall */
@media (min-width: 1365px) and (min-height: 931px) {
    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
        height: 450px !important;
        overflow-y: auto !important;
        overflow-x: auto !important;
    }
    
    .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
        height: 450px !important;
        overflow-y: auto !important;
        overflow-x: auto !important;
    }
  }
  