
.css-3su884-MuiPaper-root{
  margin-top: 20%;
}
 .css-a35cbc{
  margin-top: 3%;
 }

::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar {
  height: 5px; /* Set the height of the scrollbar */
}
::-webkit-scrollbar-track{
  background-color:#f1f1f1;
}
::-webkit-scrollbar-thumb{
   background-color: rgb(194, 188, 188);
}

/* 1365 * 931 */

@media (min-width: 1365px) and (min-height: 931px) {
  /* Your responsive styles go here */
}