.containerStyle {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center',
};

.dropzoneStyles {
  border: '2px solid #cccccc';
  border-radius: '4px';
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center';
  padding: '20px';
  cursor: 'pointer';
  box-shadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.2)';
  margin-top: 12% !important;
}

.errorStyle {
  color: 'red',
}

.tableContainerStyle {
  padding: '16px';
  height: '24rem' !important;
  overflow-y: auto;
}

.tableStyle {
  border: '1px solid #ccc',
}

.tableHeadCellStyle {
  border: '1px solid #ccc';
  padding: '8px',
};

.rowStyle {
  background-color: '#f7c5c3',
};

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  margin-top: 7%;
}

@media (min-width: 1365px) and (min-height: 931px) {

  .tableContainer .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    overflow-y: auto;
    height: 40rem !important;
    /* margin-top: 4% !important; */
  }

}