.mainEmail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80%;
}

 .left-column {
     width: 46%;
     padding: 10px;
     border: 1px solid #ccc;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
     border-radius: 5px;
     overflow: auto;
 }

.email-list {
    display: flex;
    flex-direction: column;
}

.email-item {
    width: 300px;
    height: 40px;
    overflow: hidden;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.email-item:hover {
   background-color: #1E90FF; /* New background color on hover */
   color: #fff; /* New text color on hover */
 }

/* .even {
   background-color: lightblue;
 } */

.odd {
    background-color: #f2f2f2;
}

.right-column {
    width: 47%;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: auto;
}

.header-list {
    display: flex;
    flex-direction: column;
    font-size: small;

}

.header-item {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    
}
.odd {
   background-color: #f2f2f2;
}

.scrollable-list {
    /* max-height: 550px; */
    /* overflow-y: auto;
    overflow-x: auto; */
    font-size: small;

}

.header-with-underline {
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.active{
   background-color: #1E90FF; 
}

@media (min-width: 1365px) and (min-height: 931px) {
   .mainEmail {
       display: flex;
       justify-content: space-between;
       width: 100%;
       height: 88%;
   }
}
