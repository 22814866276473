.buttonContainer{
    margin-left: 1%;
    margin-top: 4%;
    margin-bottom: 5%;
   }
/* 
 .contentContainer {
    position: relative;
} */

.underline {
    /* position: absolute; */
    left: 0;
    bottom: -10px; /* Adjust this value as needed */
    width: 100%;
    height: 1px;
    background-color: black; /* Adjust color as needed */
    margin-top: 20px; /* Adjust margin as needed */
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select{
margin-bottom: 12px;
}