.conatainer {
  display: flex;
  width: 100%;
}

.inputStyles {
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 20px !important;
}

.AttachmentStyles {
  width: 50%;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  overflow-x: auto;
  height: 180px;

}

.iconContainer {
  margin-right: 5%;
}

.InfoIconButton {
  height: 20px !important;
  width: 20px !important;
  color: #B2BEB5;
}

.fileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputLabelStyling {
  justify-content: flex-start !important;
  padding: 10px !important;
  cursor: pointer;
  display: inline-block !important;
  font-size: small !important;
  font-weight: bold !important;
  border: 1px solid #ccc !important;
  transition: 'border-color 0.3s ' !important;
  border-radius: 4px;
}

.removeIcon {
  display: flex;
  justify-content: flex-end;
}

.AttachmentNameConatiner {
  margin: 2%;
  list-style-type: none;
  text-align: center;
  overflow: hidden;
  white-space: noWrap;
  align-items: center;
  justify-content: center;
}

.attchmentName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  transition: 'white-space 0.3s';
}

.signatureButton {
  /* margin: "2%", color: "black", borderRadius: "20px", backgroundColor: "white", '&:hover': {
    background: "white",
    color: "black" */
  margin: 2% !important;
  color: black !important;
  background-color: white !important;
  border-radius: 20px !important;

}

.signatureButton:hover {
  background: white !important;
  color: black !important;
}

.emailSignatureEditor {
  width: 100%;
  height: 250px;
  padding: 10px;
  box-sizing: border-box;
  /* margin-bottom: 5px; */
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  outline: none;
}

.chipContainerStyles {
  margin: 10px;
  display: flex !important;
  flex-direction: column !important;
  width: '50%';
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 5px;
  position: relative;
  overflow-x: auto;
  width: 350px;
  height: 180px;
}


.file-style {
  margin: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: '50%';
  padding: 10px;
}

.labelStyles {
  margin-bottom: '10px';
  font-weight: bold;
  font-size: small;
  margin-right: 2%;

}

.selectLabelStyle {
  /* fontSize: "small", fontWeight: "bold", width: "80px" */
  font-size: small;
  font-weight: bold;
  width: 80px;
}

.dropDownStyles {
  /* fontWeight: "550", fontSize: "small", height: "30px", width: "200px", marginLeft: "28%", marginTop: "-7%"  */
  font-weight: 550 !important;
  font-size: small !important;
  height: 30px !important;
  width: 200px !important;
  margin-left: 28% !important;
  margin-top: -7% !important;
}

.chiplLabel {
  /* ontSize: "small", fontWeight: "bold" */
  font-size: small;
  font-weight: bold;
}

.chipStyles {
  /* margin: '4px', fontWeight: "550", fontSize: "small" */
  margin: 4px !important;
  font-weight: 550 !important;
  font-size: small !important;
}


.scrollable-chip {
  max-height: 100px;
  overflow-y: auto;
  white-space: nowrap;
  overflow-x: auto;
}

.scrollable-chip .MuiChip-root {
  white-space: nowrap;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Adjust the overlay color and opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.component-content {
  position: relative;
  z-index: 1;
  background-color: white;
  /* Adjust the background color as needed */
  padding: 16px;
}

.selectEmail {
  font-weight: 550;
  font-size: small;
  height: 30px;
  width: 200px;
  margin-left: 28%;
  margin-top: -7%;
  border-radius: 10px;

}


.header-with-underline {
  border-bottom: 1px solid #ccc;
  text-align: left;
}


@media (min-width: 1365px) and (min-height: 931px) {

  /* Your responsive styles go here */
  .chipContainerStyles {
    margin: 10px;
    display: flex !important;
    flex-direction: column !important;
    width: '50%';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 5px;
    position: relative;
    /* width: 100%; */
    /* height: 100vh; */
    overflow-x: auto;
    width: 350px;
    height: 248px;
  }

  .file-style {
    margin: 10px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    width: '50%';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    height: 92%;
  }

}