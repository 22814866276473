.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
  }
  
  .left-content {
    width: 50%;
    background-color: #1A4266;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
  
  .right-content {
    width: 50%;
    display: flex;
    min-height: 100vh;
    background-color: #f2f2f2;
    justify-content: center;
    align-items: center;
  }
  
 
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
